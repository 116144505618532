import { routes } from "../../../routes";
import annaDaan from "../../../assets/images/anna-daan.jpg";
import dietySeva from "../../../assets/images/deity-seva.jpg";
import eHundi from "../../../assets/images/e-hundi.jpg";
import gauSeva from "../../../assets/images/gau-seva.jpg";
import generalDonation from "../../../assets/images/general-donation.jpg";
import gitaDaan from "../../../assets/images/gita-daan.jpg";
import monthlyRadharaniKitchen from "../../../assets/images/monthly-radharani-kitchen.jpg";
import radharaniKitchen from "../../../assets/images/radharani-kitchen.jpg";
import recurriumDonation from "../../../assets/images/recurrium-donation.jpg";
import sadhuSeva from "../../../assets/images/sadhu-seva.jpg";
import giveSrimadBhagavatam from "../../../assets/images/give-srimad-bhagavatam.jpg";
import vidyaDaan from "../../../assets/images/vidya-daan.jpg";

export const paragraphs = [
  "As said in Bhagavad Gita 18.5- acts of sacrifice, charity and penance are not to be given up; they must be performed. Indeed, sacrifice, charity and penance purify even the great souls.",
];

export const donationItems = [
  {
    title: "Anna Daan",
    image: annaDaan,
    route: routes.annaDaan,
  },
  {
    title: "Deity Seva",
    image: dietySeva,
    route: routes.deityseva,
  },
  {
    title: "E-Hundi",
    image: eHundi,
    route: routes.ehindu,
  },
  {
    title: "Gau Seva",
    image: gauSeva,
    route: routes.gauSeva,
  },
  {
    title: "General Donation",
    image: generalDonation,
    route: routes.generaldonation,
  },
  {
    title: "Gita Daan",
    image: gitaDaan,
    route: routes.gitaDaan,
  },
  {
    title: "Monthly Radharani's Kitchen Seva",
    image: monthlyRadharaniKitchen,
    route: routes.monthlySeva,
  },
  {
    title: "Radharani's Kitchen Seva",
    image: radharaniKitchen,
    route: routes.radharaniKitchenSeva,
  },
  {
    title: "Recurrium Donation",
    image: recurriumDonation,
    route: routes.recurriumdonation,
  },
  {
    title: "Sadhu Seva",
    image: sadhuSeva,
    route: routes.sadhubhojan,
  },
  {
    title: "Srimad Bhagavatam",
    image: giveSrimadBhagavatam,
    route: routes.givesrimadbhagavatam,
  },
  {
    title: "Vidya Daan",
    image: vidyaDaan,
    route: routes.vidyadaan,
  },
];
