import styled from 'styled-components';
import { sizes } from "../../../style";

export const ViewAllPageWrapper = styled.div`
  padding: 20px;
  margin: auto;
  width: 85%;

  @media (max-width: ${sizes.tablet}) {
    width: 90%;
  }
  @media (max-width: ${sizes.mobileL}) {
    width: 95%;
    padding: 15px;
  }
`;

export const FoldersContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;

  @media (max-width: ${sizes.tablet}) {
    gap: 15px;
  }
  @media (max-width: ${sizes.mobileL}) {
    gap: 10px;
  }
`;

export const FolderCard = styled.div`
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  width: calc(25% - 20px);

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }

  @media (max-width: ${sizes.tablet}) {
    width: calc(33.33% - 15px);
  }
  }
  @media (max-width: ${sizes.mobileL}) {
    width: 100%;
  }
`;

export const FolderThumbnail = styled.img`
  width: 100%;
  height: auto;
  border-bottom: 1px solid #ddd;
`;

export const FolderDetails = styled.div`
  padding: 15px;

  h3 {
    margin: 0 0 10px;
    font-size: 1rem;

    @media (max-width: ${sizes.tablet}) {
      font-size: 0.9rem;
    }
    @media (max-width: ${sizes.mobileL}) {
      font-size: 0.85rem;
    }
  }

  p {
    margin: 0;
    color: gray;
    font-size: 0.85rem;

    @media (max-width: ${sizes.tablet}) {
      font-size: 0.8rem;
    }
    @media (max-width: ${sizes.mobileL}) {
      font-size: 0.75rem;
    }
  }
`;

export const LoadingMessage = styled.div`
  text-align: center;
  font-size: 18px;
  color: #555;

  @media (max-width: ${sizes.tablet}) {
    font-size: 16px;
  }
  @media (max-width: ${sizes.mobileL}) {
    font-size: 14px;
  }
  @media (max-width: ${sizes.mobileS}) {
    font-size: 12px;
  }
`;
