import { createClient, Entry } from 'contentful-management';

// Define types for environment variables
declare const process: {
  env: {
    REACT_APP_CONTENTFUL_MANAGEMENT_API_ACCESS_TOKEN: string;
    REACT_APP_CONTENTFUL_SPACE_ID: string;
  };
};

// Initialize the Contentful management client
const managementClient = createClient({
  accessToken: process.env.REACT_APP_CONTENTFUL_MANAGEMENT_API_ACCESS_TOKEN,
});

// Define the `removeOldEntries` function
export const removeOldEntries = async (): Promise<void> => {
  try {
    // Fetch the space and environment
    const space = await managementClient.getSpace(process.env.REACT_APP_CONTENTFUL_SPACE_ID);
    const environment = await space.getEnvironment('master'); // Replace 'master' if your environment is different

    // Fetch all entries of the 'galleryFolder' content type
    const response = await environment.getEntries({
      content_type: 'galleryFolder',
    });

    const currentDate = new Date();
    const cutoffDate = new Date(currentDate);
    cutoffDate.setDate(cutoffDate.getDate() - 15); // Cutoff date logic: 15 days ago

    // Separate entries into "Festival Darshan" and other categories
    const festivalEntries: Entry[] = [];
    const otherEntries: Entry[] = [];

    response.items.forEach((item: any) => {
      const categoryArray = item.fields.category?.['en-US'];
      if (!Array.isArray(categoryArray)) {
        console.warn(`Skipping entry with invalid category: ${item.sys.id}`);
        return;
      }

      const category = categoryArray[0];
      if (category === 'Festival Darshan') {
        festivalEntries.push(item);
      } else {
        otherEntries.push(item);
      }
    });

    // Handle "Festival Darshan" entries: Keep the latest 15 entries
    if (festivalEntries.length > 15) {
      // Sort entries by creation date
      festivalEntries.sort((a, b) => new Date(a.sys.createdAt).getTime() - new Date(b.sys.createdAt).getTime());
      const excessEntries = festivalEntries.slice(0, festivalEntries.length - 15);

      for (const entry of excessEntries) {
        try {
          if (entry.isPublished()) await entry.unpublish();
          await entry.delete();
          console.log(`Deleted Festival Darshan entry with ID: ${entry.sys.id}`);
        } catch (error) {
          console.error(`Failed to delete Festival Darshan entry with ID: ${entry.sys.id}`, error);
        }
      }
    }

    // Handle other entries based on date logic
    const outdatedEntries = otherEntries.filter((item) => {
      const folderDate = new Date(item.fields.date?.['en-US']);
      return folderDate < cutoffDate;
    });

    for (const entry of outdatedEntries) {
      try {
        if (entry.isPublished()) await entry.unpublish();
        await entry.delete();
        console.log(`Deleted outdated entry with ID: ${entry.sys.id}`);
      } catch (error) {
        console.error(`Failed to delete outdated entry with ID: ${entry.sys.id}`, error);
      }
    }
  } catch (error) {
    console.error('Error removing old entries:', error);
  }
};

// Define the `ensureMaxVideos` function
export const ensureMaxVideos = async (maxCount: number = 50): Promise<void> => {
  try {
    // Fetch the space and environment
    const space = await managementClient.getSpace(process.env.REACT_APP_CONTENTFUL_SPACE_ID);
    const environment = await space.getEnvironment('master'); // Replace 'master' if your environment is different

    // Fetch all video entries, sorted by creation date
    const response = await environment.getEntries({
      content_type: 'kirtansandLectures',
      order: 'sys.createdAt',
    });

    const videos: Entry[] = response.items;

    if (videos.length > maxCount) {
      const excessVideos = videos.slice(0, videos.length - maxCount); // Get the oldest entries

      for (const video of excessVideos) {
        try {
          if (video.isPublished()) await video.unpublish(); // Unpublish the entry
          await video.delete(); // Delete the entry
          console.log(`Deleted video entry with ID: ${video.sys.id}`);
        } catch (error) {
          console.error(`Failed to delete video entry with ID: ${video.sys.id}`, error);
        }
      }
    }
  } catch (error) {
    console.error('Error ensuring max videos:', error);
  }
};
