import styled from "styled-components";
import title from "../../assets/images/navbar-bg.png";
import { Link } from "react-router-dom";
import Marquee from "react-fast-marquee";
import { devices } from "../../style";

export const NavStyle = styled.nav`
  background-image: url(${title});
  background-repeat: repeat;
  position: fixed;
  display: none;

  @media (${devices.laptop}) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 90px;
    padding: 0 10px;
    margin-top: 50px;
  }

  @media (${devices.laptopL}) {
    height: 90px;
    padding: 0 100px;
  }

  @media (${devices.desktop}) {
    height: 105px;
    width: 100%
  }
`;

export const StyledLink = styled(Link)`
  color: #fff !important;
  text-decoration: none;
  font-family: Overlock-Regular, Arial, Helvetica, sans-serif;
  position: relative;
  transform: translateX(-5%);
  font-size: 1.0rem;
  padding: 5px 10px;

  @media (${devices.laptopM}) {
    transform: translate(-5%, -5px);
    font-size: 1.1rem;
    padding: 10px 20px;
  }
  

  &:not(:has(img)) {
    &:hover {
      background-color: #ad0d0d; / Background color on hover /
      color: #fff;
      border-radius: 50px; / Fully rounded effect /
    }
  }
`;

export const ImgStyle = styled.img`
  height: 70px;
  margin: auto;

  @media (${devices.mobileES}) {
    display: none;
  }

  @media (${devices.mobileS}) {
    display: none;
  }

  @media (${devices.mobileM}) {
    display: none;
  }

  @media (${devices.mobileL}) {
    display: none;
  }

  @media (${devices.tablet}) {
    display: flex;
    height: 70px;
  }

  @media (${devices.laptop}) {
    height: 80px;
  }

  @media (${devices.laptopL}) {
    height: 80px;
  }

  @media (${devices.desktop}) {
    display: flex;
    height: 100px;
  }
`;

export const SocialMediaIconsStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
`;

export const IconsStyle = styled.a`
  color: #fff;
  margin-top: 5px;
  padding: 10px;
  transition: background-color 0.2s ease;

  @media (min-width: 1024px) and (max-width: 1100px) {
   position: relative;
   left: -10px;
  }
`;

export const MarqueeWrapper = styled(Marquee)`
  color: #fff;
  background-color: red;
  font-family: Overlock-Regular, cursive, sans-serif;
  height: 75px;
  width: 85%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  @media (${devices.mobileES}) {
    display: none;
    height: 0%;
    width: 0%;
  }

  @media (${devices.laptop}) {
    height: 55px;
    width: 85%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9999;
  }

  @media (${devices.laptopL}) {
    height: 55px;
    width: 85%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9999;
  }

  @media (${devices.desktop}) {
    height: 55px;
    width: 85%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9999;
  }
`;

export const SocialMediaIconWrapper = styled.div`
  background-color: #ad0d0d;
  height: 55px;
  width: 15%;
  position: fixed;
  top: 0;
  left: 85%;
  right: 0%;
  display: flex;
  display: right;
  

  @media (${devices.mobileES}) {
    display: none;
  }
    
  @media (${devices.laptop}) {
    height: 55px;
    width: 15%;
    position: fixed;
    top: 0;
    left: 85%;
    right: 0%;
    z-index: 9999;
    display: flex;
    display: right;
  }

  @media (${devices.laptopL}) {
    height: 55px;
    width: 15%;
    position: fixed;
    top: 0;
    left: 85%;
    right: 0%;
    z-index: 9999;
    display: flex;
    display: right;
  }

  @media (${devices.desktop}) {
    height: 55px;
    width: 15%;
    position: fixed;
    top: 0;
    left: 85%;
    right: 0%;
    z-index: 9999;
    display: flex;
    display: right;
  }
`;
