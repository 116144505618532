import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {
  DropdownMenuWrapper,
  DropdownToggleWrapper,
  TransparentDropdown,
} from "./style";

interface DropdownItem {
  title: string;
  path: string;
}

interface DropdownProps {
  items: DropdownItem[];
  toggleTitle: string;
}

export const PageDropdown: React.FC<DropdownProps> = ({
  items,
  toggleTitle,
}) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  // Close dropdown after clicking an item
  const handleItemClick = (path: string) => {
    navigate(path);
    setIsOpen(false);
  };

  return (
    <TransparentDropdown
      isOpen={isOpen} // Pass the isOpen state to maintain border-radius
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
    >
      <Dropdown show={isOpen}>
        <DropdownToggleWrapper>
          <Dropdown.Toggle>{toggleTitle}</Dropdown.Toggle>
        </DropdownToggleWrapper>
        <DropdownMenuWrapper>
          <Dropdown.Menu>
            {items.map((item) => (
              <Dropdown.Item
                key={item.path}
                onClick={() => handleItemClick(item.path)}
              >
                {item.title}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </DropdownMenuWrapper>
      </Dropdown>
    </TransparentDropdown>
  );
};
