import styled from "styled-components";
import { Link } from "react-router-dom";

// Wrapper for the donation page
export const DonationPageWrapper = styled.div`
  padding: 2%;
  background-color: transparent;
  margin: 0 auto;
  width: 88%;
  box-sizing: border-box;

  @media (min-width: 425px) and (max-width: 768px) {
    width: 70%;
  }

`;

// Section for donation categories
export const DonationCategorySection = styled.div`
  margin-bottom: 40px;
`;

// Container for donation items (Folders)
export const DonationFoldersContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
`;

// Individual donation item (Folder Card)
export const DonationFolderCard = styled.div`
  cursor: pointer;
  flex: 1 1 calc(25% - 20px); // Four items per row
  max-width: calc(25% - 20px);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background: #fff;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease;

  &:hover {
    transform: translateY(-5px);
  }

  @media (max-width: 1024px) {
    flex: 1 1 calc(50% - 20px);
    max-width: calc(50% - 20px);
  }

  @media (max-width: 768px) {
    flex: 1 1 100%;
    max-width: 100%;
  }
`;

// Thumbnail image for donation folders
export const DonationFolderThumbnail = styled.img`
  width: 100%;
  height: auto;
  border-bottom: 1px solid #ddd;
`;

// Details section inside each donation card
export const DonationFolderDetails = styled.div`
  padding: 10px;

  h3 {
    font-size: 1rem;
    margin: 0;
  }

  p {
    margin: 5px 0 0;
    color: #666;
    font-size: 0.85rem;
  }
`;

// Styled link component (for navigation)
export const DonationStyledLink = styled(Link)`
  color: #fff;
  text-decoration: none;

  &:hover {
    color: #ad0d0d;
  }
`;
