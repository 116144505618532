import styled from 'styled-components';
import { sizes } from "../../../style";

export const KirtanLecturesPageWrapper = styled.div`
  margin: 0 auto; /* Center the wrapper */
  width: 95%;
`;

export const VideosGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Center-align the cards horizontally */
  align-items: flex-start; /* Align items to the top */
  gap: 20px; /* Add spacing between the cards */
`;

export const VideoCardWrapper = styled.div`
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  width: 300px;
  margin: 10px;
  cursor: pointer;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
  }

  /* Adjust card width dynamically based on screen sizes */
  @media (min-width: ${sizes.mobileS}) {
    width: calc(100% / 1 - 20px); /* 1 card per row */
  }

  @media (min-width: ${sizes.mobileL}) {
    width: calc(100% / 3 - 20px); /* 2 cards per row */
  }

  @media (min-width: ${sizes.tablet}) {
    width: calc(100% / 4 - 20px); /* 3 cards per row */
  }

  @media (min-width: ${sizes.tabletL}) {
    width: calc(100% / 4 - 20px); /* 4 cards per row */
  }

  @media (min-width: ${sizes.laptop}) {
    width: calc(100% / 5 - 20px); /* 5 cards per row */
  }

  @media (min-width: ${sizes.laptopL}) {
    width: calc(100% / 6 - 20px); /* 6 cards per row */
  }

  @media (min-width: ${sizes.desktop}) {
    width: calc(100% / 8 - 20px); /* 8 cards per row for ultra-wide desktops */
  }

`;

export const VideoThumbnail = styled.div`
  position: relative;
  display: block;
  width: 100%;
`;

export const ThumbnailImage = styled.img`
  display: block;
  width: 100%;
`;

export const PlayOverlay = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.7);
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PlayIcon = styled.img`
  width: 40px;
  height: 40px;
`;

export const VideoInfo = styled.div`
  padding: 15px;
  text-align: left;
`;

export const VideoTitle = styled.h3`
  font-size: 1rem;
  color: #333;
  margin: 10px 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const VideoDate = styled.div`
  font-size: 0.875rem;
  color: #777;
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const VideoModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
`;

export const ModalContent = styled.div`
  position: relative;
  width: 90%;
  max-width: 900px;
  background: #000;
  border-radius: 12px;
  overflow: visible;
`;

export const CloseButton = styled.button`
  position: absolute;
  top: -30px;
  right: -20px;
  background: transparent;
  border: none;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background: #ddd;
    color: #333;
  }

`;

export const IFrame = styled.iframe`
  width: 100%;
  height: 500px;
  border: none;
  display: block;
`;
