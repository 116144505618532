import {
  NavStyle,
  StyledLink,
  ImgStyle,
  IconsStyle,
  MarqueeWrapper,
  SocialMediaIconWrapper,
} from "./style";
import { PageDropdown } from "../Dropdown";
import { dropdownServicesInfo, socialMediaIcon, mediaDropdownItems } from "../Navbar/constant";
import { MDBIcon } from "mdb-react-ui-kit";
import logoUrl from "../../assets/images/isckon-logo.png";
import { routes } from "../../routes";

export const NavigationBar = () => {
  return (
    <>
      <MarqueeWrapper pauseOnHover={true}>
        The Official Website Of The International Society for Krishna
        Consiousness (ISKCON).
      </MarqueeWrapper>
      <div className="container">
        <SocialMediaIconWrapper>
          {socialMediaIcon.map((item) => (
            <IconsStyle key={item.name} href={item.href} target="_blank">
              <MDBIcon fab icon={item.icon} />
            </IconsStyle>
          ))}
        </SocialMediaIconWrapper>
      </div>
      <NavStyle className="navbar fixed-top">
        <div className="container">
          {/* Left Side Navigation Items */}
            <StyledLink to={routes.home}>ISKCON</StyledLink>
            <PageDropdown items={dropdownServicesInfo} toggleTitle="Services" />
            <StyledLink to={routes.visitUs}>Visit Us</StyledLink>
            <PageDropdown items={mediaDropdownItems} toggleTitle="Media" />

          {/* Centered Logo */}
          <StyledLink to={routes.home}>
            <ImgStyle src={logoUrl} alt="ISKCON Logo" />
          </StyledLink>

          {/* Right Side Navigation Items */}
            <StyledLink to={routes.donationMainPage}>Donation</StyledLink>
            <StyledLink to={routes.festival}>Festival</StyledLink>
            <StyledLink to={routes.contact}>Contact</StyledLink>
            {/* <StyledLink to={routes.blogs}>Blogs</StyledLink> */}
            <StyledLink to={routes.home}>Blogs</StyledLink>
        </div>
      </NavStyle>
    </>
  );
};
