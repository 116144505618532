import React, { useState, useEffect } from 'react';
import noRecordFoundImage from "../../../assets/images/no-record-found.png";
import { useSearchParams, useNavigate } from 'react-router-dom';
import client from '../../../utils/contentfulClient';
import { removeOldEntries } from '../../../utils/contentfulUtils';
import { HeadingStyle, TitleWrapper } from "../../TitleBase/style";
import { routes } from "../../../routes";
import {
  FolderPageWrapper,
  FolderHeader,
  HeaderContent,
  HeaderLeft,
  HeaderRight,
  CategorySelect,
  DateNavigation,
  ArrowButton,
  CurrentDate,
  PhotosContainer,
  PhotoCard,
  PhotoThumbnail,
  LightboxOverlay,
  LightboxContent,
  LightboxClose,
  LightboxArrow,
  LoadingMessage,
  NoRecordFoundWrapper,
} from './style';

interface Folder {
  date: string;
  photos: string[];
}

const NoRecordFound: React.FC = () => (
  <NoRecordFoundWrapper>
    <img src={noRecordFoundImage} alt="No Record Found" />
    <h2>No Record Found !!</h2>
  </NoRecordFoundWrapper>
);

export const FolderPage: React.FC = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const date = searchParams.get('date') || new Date().toISOString().split('T')[0];
  const category = searchParams.get('category') || 'Sringar Darshan';

  const [folder, setFolder] = useState<Folder | null>(null);
  const [noData, setNoData] = useState(false);
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const formatDate = (isoDate: string): string => {
    const dateObj = new Date(isoDate);
    const day = String(dateObj.getDate()).padStart(2, '0');
    const month = dateObj.toLocaleString('default', { month: 'long' });
    const year = dateObj.getFullYear();
    return `${day} ${month} ${year}`;
  };

  const changeDate = (offset: number) => {
    const currentDate = new Date(date);
    currentDate.setDate(currentDate.getDate() + offset);
    const newDate = currentDate.toISOString().split("T")[0];
    navigate(`${routes.folderPage}?category=${(category)}&date=${(newDate)}`);
  };

  const handleCategoryChange = (newCategory: string) => {
    navigate(`${routes.folderPage}?category=${(newCategory)}&date=${(date)}`);
  };

  useEffect(() => {
    const fetchFolder = async () => {
      setFolder(null);
      setNoData(false);

      await removeOldEntries();

      try {
        const response = await client.getEntries({
          content_type: 'galleryFolder',
          'fields.date': date,
          'fields.category': category,
        });

        if (response.items.length > 0) {
          const folderData = response.items[0].fields;
          setFolder({
            date: folderData.date as string,
            photos: (folderData.images as any[]).map(
              (img) => img.fields.file.url as string
            ),
          });
        } else {
          setNoData(true);
        }
      } catch (error) {
        console.error('Error fetching folder from Contentful:', error);
        setNoData(true);
      }
    };

    fetchFolder();
  }, [date, category]);

  const openLightbox = (index: number) => {
    setCurrentImageIndex(index);
    setIsLightboxOpen(true);
  };

  const closeLightbox = () => setIsLightboxOpen(false);

  const movePrev = () => {
    if (folder) {
      setCurrentImageIndex(
        (currentImageIndex + folder.photos.length - 1) % folder.photos.length
      );
    }
  };

  const moveNext = () => {
    if (folder) {
      setCurrentImageIndex((currentImageIndex + 1) % folder.photos.length);
    }
  };

  return (
    <FolderPageWrapper>
      <TitleWrapper>
        <HeadingStyle />
      </TitleWrapper>
      <FolderHeader>
        <HeaderContent>
          <HeaderLeft>
            <h1>{category} Gallery</h1>
            <p>
              Exploring the divine beauty and spiritual essence of sacred
              adornments through intricate, culturally rich artistic
              expressions.
            </p>
          </HeaderLeft>
          <HeaderRight>
            <CategorySelect
              value={category}
              onChange={(e) => handleCategoryChange(e.target.value)}
            >
              <option value="Sringar Darshan">Sringar Darshan</option>
              <option value="Mangala Darshan">Mangala Darshan</option>
              <option value="Festival Darshan">Festival Darshan</option>
            </CategorySelect>
            <DateNavigation>
              <ArrowButton onClick={() => changeDate(-1)}>⬅️</ArrowButton>
              <CurrentDate>{formatDate(date)}</CurrentDate>
              <ArrowButton onClick={() => changeDate(1)}>➡️</ArrowButton>
            </DateNavigation>
          </HeaderRight>
        </HeaderContent>
      </FolderHeader>

      {noData ? (
        <NoRecordFound />
      ) : folder ? (
        <PhotosContainer
          breakpointCols={{
            default: 4,
            1100: 3,
            700: 2,
            500: 1,
          }}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column"
        >
          {folder.photos.map((photo, index) => (
            <PhotoCard key={index} onClick={() => openLightbox(index)}>
              <PhotoThumbnail src={photo} alt={`Photo ${index + 1}`} />
            </PhotoCard>
          ))}
        </PhotosContainer>
      ) : (
        <LoadingMessage>Loading...</LoadingMessage>
      )}

      {isLightboxOpen && folder && (
        <LightboxOverlay onClick={closeLightbox}>
          <LightboxContent onClick={(e) => e.stopPropagation()}>
            <img src={folder.photos[currentImageIndex]} alt="Lightbox" />
            <LightboxClose onClick={closeLightbox}>&times;</LightboxClose>
            <LightboxArrow isPrev onClick={(e) => {
              e.stopPropagation();
              movePrev();
            }}>
              &#10094;
            </LightboxArrow>
            <LightboxArrow onClick={(e) => {
              e.stopPropagation();
              moveNext();
            }}>
              &#10095;
            </LightboxArrow>
          </LightboxContent>
        </LightboxOverlay>
      )}
    </FolderPageWrapper>
  );
};

export default FolderPage;
