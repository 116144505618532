import { routes } from "../../routes";

export const dropdownServicesInfo = [
  { name: "Life Membership", url: routes.lifeTimeMembership },
  { name: "Kirtan At Iskcon", url: routes.kirtanAtIskcon },
  { name: "Iskcon Youth Forum", url: routes.iskconYouthForum },
  { name: "Guest House", url: routes.guestHouse },
  { name: "Goushala", url: routes.gaushala },
  { name: "Devotee Kitchen", url: routes.devoteeKitchen },
  { name: "Deity Worship", url: routes.deityWorship },
  { name: "Book Distribution", url: routes.bookDistribution },
  { name: "Govinda's Food", url: routes.govindaFood },
];

export const mediaDropdownItems = [
  { name: "Daily Darshan", url: routes.dailyDarshan},
  { name: "Kirtans & Lectures", url: routes.kirtansLectures },
];