import { createClient, ContentfulClientApi } from 'contentful';

// Ensure environment variables are defined at runtime
if (!process.env.REACT_APP_CONTENTFUL_SPACE_ID || !process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN) {
  throw new Error(
    'Missing required Contentful environment variables: REACT_APP_CONTENTFUL_SPACE_ID or REACT_APP_CONTENTFUL_ACCESS_TOKEN'
  );
}

// Create the Contentful client
const client: ContentfulClientApi<undefined> = createClient({
  space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
  accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
});

export default client;
