import React, { useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { removeOldEntries } from '../../../utils/contentfulUtils';
import client from '../../../utils/contentfulClient';
import titleBaseImg from "../../../assets/images/title-base.png";
import { HeadingStyle, ImageTitle, TitleWrapper } from "../../TitleBase/style";
import { routes } from "../../../routes";
import {
  ViewAllPageWrapper,
  FoldersContainer,
  FolderCard,
  FolderThumbnail,
  FolderDetails,
  LoadingMessage
} from './style';

interface Folder {
  date: string;
  title: string;
  photos: string[];
}


export const ViewAllPage: React.FC = () => {
  const [searchParams] = useSearchParams(); // Use useSearchParams instead of useParams
  const category = searchParams.get("category"); // Retrieve 'category' from query params
  const [folders, setFolders] = useState<Folder[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchFolders = async () => {
      await removeOldEntries();

      try {
        const response = await client.getEntries({
          content_type: 'galleryFolder',
          'fields.category[match]': category, // First try filtering by category
          order: ["-fields.date"] as [
            `fields.${string}` | `-fields.${string}` | "sys.contentType.sys.id" | "-sys.contentType.sys.id" | `fields.${string}.sys.id` | `-fields.${string}.sys.id`
          ],
        });

        // If no results for category, fallback to filtering by title
        if (response.items.length === 0) {
          const fallbackResponse = await client.getEntries({
            content_type: 'galleryFolder',
            'fields.title[match]': category, // Filter by title as a fallback
            order: ["-fields.date"] as [
                `fields.${string}` | `-fields.${string}` | "sys.contentType.sys.id" | "-sys.contentType.sys.id" | `fields.${string}.sys.id` | `-fields.${string}.sys.id`
              ],
          });
          setFolders(
            fallbackResponse.items.map((item: any) => ({
              date: item.fields.date,
              title: item.fields.title,
              photos: item.fields.images.map((img: any) => img.fields.file.url),
            }))
          );
        } else {
          setFolders(
            response.items.map((item: any) => ({
              date: item.fields.date,
              title: item.fields.title,
              photos: item.fields.images.map((img: any) => img.fields.file.url),
            }))
          );
        }
      } catch (error) {
        console.error('Error fetching data from Contentful:', error);
      }
    };

    fetchFolders();
  }, [category]);

  return (
    <ViewAllPageWrapper>
      <TitleWrapper>
        <HeadingStyle>
          <h1>{category} Gallery</h1>
          <ImageTitle src={titleBaseImg} alt="Title Image" />
        </HeadingStyle>
      </TitleWrapper>
      {folders.length === 0 ? (
        <LoadingMessage>Loading...</LoadingMessage>
      ) : (
        <FoldersContainer>
          {folders.map((folder, index) => (
            <FolderCard
              key={index}
              onClick={() => navigate(`${routes.folderPage}?category=${(category)}&date=${(folder.date)}`)}
            >
              <FolderThumbnail src={folder.photos[0]} alt="Thumbnail" />
              <FolderDetails>
                <h3>{folder.title}</h3>
                <p>{new Date(folder.date).toDateString()}</p>
              </FolderDetails>
            </FolderCard>
          ))}
        </FoldersContainer>
      )}
    </ViewAllPageWrapper>
  );
};

export default ViewAllPage;
