import React, { useState, useEffect } from "react";
import client from "../../../utils/contentfulClient";
import { Button, ButtonContainer, Table } from "./style";
import { DonationPagesDescription } from "../PageLayout";
import { Payment } from "../Payment/donationTable";
import { useParams } from "react-router-dom";

interface DonationPageProps {
  singleCategory?: boolean;
  categoryKey?: string;
}

type DonationPageData = {
  title: string;
  quote: string[];
  options: DonationOption[];
  categories: Record<string, DonationCategory>;
};

type DonationOption = {
  label: string;
};

type DonationCategory = {
  title: string;
  donations: {
    id: number;
    title: string;
    amount: number;
  }[];
};



const fetchDonationPageData = async (
  categoryKey?: string
): Promise<DonationPageData | null> => {
  const response = await client.getEntries({
    content_type: "donationPage",
  });

  const formattedItems = response.items.map((item: any) => ({
    title: item.fields.title,
    quote: item.fields.qoute.content as string[],
    options: item.fields.options.map((opt: any) => ({
      label: opt.label,
    })),
    categories: item.fields.categories.reduce(
      (acc: Record<string, DonationCategory>, category: any) => {
        const donationCategory: DonationCategory = {
          title: category.title,
          donations: category.donations.map((donation: any) => ({
            id: donation.id,
            title: donation.title,
            amount: donation.amount,
          })),
        };

        acc[donationCategory.title] = donationCategory;
        return acc;
      },
      {}
    ),
  }));

  // Return the filtered item or null if not found
  if (categoryKey) {
    const filteredItem = formattedItems.find(
      (item) => item.title === categoryKey // Match categoryKey with the title
    );
    return filteredItem || null;
  }
  // Return the first entry if no categoryKey is provided
  return formattedItems.length > 0 ? formattedItems[0] : null;
};




export const DynamicDonationPage: React.FC<DonationPageProps> = ({
  singleCategory = false,
}) => {
  const { categoryKey } = useParams<{ categoryKey: string }>();

  const [title, setTitle] = useState<string>("");
  const [quote, setQuote] = useState<string[]>([]);
  const [options, setOptions] = useState<DonationOption[]>([]);
  const [donationCategories, setDonationCategories] = useState<
    Record<string, DonationCategory>
  >({});
  const [selectedOption, setSelectedOption] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const data = await fetchDonationPageData(categoryKey);

        if (!data) {
          throw new Error(`No data found for category key: ${categoryKey}`);
        }

        const { title, quote, options, categories } = data;

        setTitle(title);
        setQuote(quote);
        setOptions(
          singleCategory && categoryKey
            ? options.filter((opt: DonationOption) => opt.label === categoryKey)
            : options
        );
        setDonationCategories(categories);

        // Set default option
        if (singleCategory && categoryKey) {
          setSelectedOption(categoryKey);
        } else if (options.length > 0) {
          setSelectedOption(options[0].label);
        }
        setError(null);
      } catch (err) {
        setError(
          `Failed to load donation page data. ${(err as Error).message}`
        );
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [singleCategory, categoryKey]);

  const handleOptionClick = (value: string) => {
    setSelectedOption(value);
  };

  const renderTable = () => {
    const selectedCategory = donationCategories[selectedOption];
    if (selectedCategory) {
      return (
        <Table>
          <Payment
            donationCategories={[selectedCategory]}
            donationPageName={
              options.length > 1
              ? `${title}:-${options.find((opt) => opt.label === selectedOption)?.label || ""}`
              : `${options.find((opt) => opt.label === selectedOption)?.label || ""}` // Only the label if a single option
            }
          />
        </Table>
      );
    }
    return <p>No data available for this category.</p>;
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <>
      <DonationPagesDescription
        title={title}
        content={quote} // Single text wrapped in an array
      />
      {options.length > 1 && !singleCategory && (
        <ButtonContainer>
          {options.map((option) => (
            <div key={option.label}>
              <Button
                className={selectedOption === option.label ? "active-button" : ""}
                onClick={() => handleOptionClick(option.label)}
              >
                {option.label}
              </Button>
            </div>
          ))}
        </ButtonContainer>
      )}
      {renderTable()}
    </>
  );
};


