import styled from "styled-components";
import Masonry from 'react-masonry-css';
import { sizes } from "../../../style";

export const FolderPageWrapper = styled.div`
  font-family: 'Arial', sans-serif;
  padding: 0;
  margin: -5% auto;
  width: 85%;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  @media (max-width: ${sizes.laptopL}) {
    margin: -6% auto;
  }
  @media (max-width: ${sizes.laptop}) {
    min-height: 60vh;
    margin: -4% auto;
  }
  @media (max-width: ${sizes.tablet}) {
    margin: -2% auto;
  }
  @media (max-width: ${sizes.mobileL}) {
    margin: 0 auto;
    width: 90%;
  }
  @media (max-width: ${sizes.mobileM}) {
    width: 95%;
  }
`;


export const FolderHeader = styled.div`
  background: linear-gradient(to right, #c0946b, #d5ac82);
  border-radius: 10px;
  padding: 20px;
  color: white;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: ${sizes.tablet}) {
    padding: 15px;
  }
  @media (max-width: ${sizes.mobileL}) {
    padding: 10px;
    margin-bottom: 15px;
  }
`;


export const HeaderContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @media (max-width: ${sizes.tablet}) {
    flex-direction: column;
  }
`;


export const HeaderLeft = styled.div`
  flex: 1;
  min-width: 200px;
  text-align: left;
  padding-right: 20px;

  h1 {
    margin: 0;
    font-size: 32px;
    font-weight: bold;
    color: white;
  }

  p {
    margin-top: 5px;
    font-size: 16px;
    color: white;
    line-height: 1.5;
  }

  @media (max-width: ${sizes.tablet}) {
    h1 {
      font-size: 28px;
    }
    p {
      font-size: 14px;
    }
  }

  @media (max-width: ${sizes.tabletL}) {
    h1 {
      font-size: 24px;
    }
    p {
      font-size: 14px;
    }
  }


  @media (max-width: ${sizes.mobileL}) {
    h1 {
      font-size: 24px;
    }
    p {
      font-size: 12px;
    }
  }

  @media (max-width: ${sizes.mobileS}) {
    h1 {
      font-size: 20px;
    }
    p {
      font-size: 12px;
    }
  }
`;


export const HeaderRight = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 10px;
  min-width: 250px;

  @media (max-width: ${sizes.tablet}) {
    align-items: center;
    min-width: 100%;
  }
`;


export const CategorySelect = styled.select`
  padding: 10px 15px;
  border-radius: 5px;
  border: 1px solid #a1221c;
  background-color: white;
  color: #555;
  font-size: 18px;
  cursor: pointer;
  width: 100%;

  @media (max-width: ${sizes.laptop}) {
    font-size: 16px;
  }
  @media (max-width: ${sizes.tablet}) {
    font-size: 14px;
    width: 100%;
  }
  @media (max-width: ${sizes.mobileL}) {
    font-size: 12px;
    padding: 8px;
    width: 100%;
  }
`;


export const DateNavigation = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: space-between; /* This ensures arrows are pushed to the edges */
  width: 100%; /* Ensures the container spans the full width */
`;


export const ArrowButton = styled.button`
  padding: 4px 10px;
  background-color: #fdf4e3;
  border: 1px solid #a1221c;
  border-radius: 5px;
  cursor: pointer;
  font-size: 18px;
  color: #a1221c;

  &:hover {
    background-color: #eedac7;
  }

  @media (max-width: ${sizes.tablet}) {
    font-size: 16px;
    
  }
  @media (max-width: ${sizes.mobileL}) {
    font-size: 14px;
`;


export const CurrentDate = styled.span`
  font-size: 20px;
  font-weight: bold;
  color: white;

  @media (max-width: ${sizes.tablet}) {
    font-size: 18px;
  }
  @media (max-width: ${sizes.mobileL}) {
    font-size: 16px;
  }
`;

export const PhotosContainer = styled(Masonry).attrs((props) => ({
  ...props,
}))`
  display: flex;
  margin-top: 20px;
  margin-bottom: 15%;

  .my-masonry-grid_column {
    padding-left: 15px; /* Add horizontal spacing between columns */
    background-clip: padding-box;
  }

  .my-masonry-grid_column > div {
    margin-bottom: 20px; /* Add spacing between items */
  }
`;

export const PhotoCard = styled.div`
  width: 100%;
  overflow: hidden;
  border-radius: 10px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: ${sizes.tablet}) {
    border-radius: 8px;
  }
  @media (max-width: ${sizes.mobileL}) {
    border-radius: 5px;
  }
`;


export const PhotoThumbnail = styled.img`
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  cursor: pointer;

  @media (max-width: ${sizes.mobileL}) {
    max-height: 90%;
  }
`;


export const NoRecordFoundWrapper = styled.div`
  text-align: center;
  background-color: white;
  border: 2px solid #ddd;
  border-radius: 10px;
  width: 100%;
  margin-bottom: 8%;

  img {
    max-height: 200px; /* Restrict the height */
  }

  h2 {
    font-size: 18px;
  }

  @media (max-width: ${sizes.tablet}) {
    h2 {
      font-size: 16px;
    }
  }
  @media (max-width: ${sizes.mobileL}) {
    h2 {
      font-size: 14px;
    }
  }
`;


export const LoadingMessage = styled.div`
  text-align: center;
  font-size: 18px;
  color: #555;

  @media (max-width: ${sizes.tablet}) {
    font-size: 16px;
  }
  @media (max-width: ${sizes.mobileL}) {
    font-size: 14px;
  }
`;



export const LightboxOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  overflow-y: auto; /* Enable vertical scroll for large images */
  padding: 20px;

  @media (max-width: ${sizes.tablet}) {
    padding: 10px;
  }
`;


export const LightboxContent = styled.div`
  position: relative;
  max-width: 90%;
  max-height: 90%;
  margin-top: 40px;

  img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5); /* Subtle shadow for depth */
    object-fit: contain; /* Ensure the image fits within the content area */
  }

  @media (max-width: ${sizes.tablet}) {
    max-width: 80%;
    max-height: 80%;
  }
`;


export const LightboxClose = styled.span`
  position: absolute;
  top: -40px;
  right: 5px;
  font-size: 36px;
  color: white;
  cursor: pointer;
  z-index: 10000;

  @media (max-width: ${sizes.tablet}) {
    font-size: 28px;
  }
  @media (max-width: ${sizes.mobileL}) {
    font-size: 24px;
  }
`;


interface LightboxArrowProps {
    isPrev?: boolean; // Indicates if this is the "previous" arrow
  }

export const LightboxArrow = styled.span<LightboxArrowProps>`
  position: absolute;
  top: 50%;
  font-size: 40px;
  color: white;
  cursor: pointer;
  z-index: 1000;

  ${({ isPrev }) => (isPrev ? "left: 20px;" : "right: 20px;")}

  @media (max-width: ${sizes.tablet}) {
    font-size: 32px;
  }
  @media (max-width: ${sizes.mobileL}) {
    font-size: 28px;
  }
`;

