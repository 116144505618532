import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  MediaPageWrapper,
  CategorySection,
  CategoryHeader,
  ViewAllButton,
  FoldersContainer,
  FolderCard,
  FolderThumbnail,
  FolderDetails,
} from "./style";
import client from "../../../utils/contentfulClient";
import titleBaseImg from "../../../assets/images/title-base.png";
import { HeadingStyle, ImageTitle, TitleWrapper } from "../../TitleBase/style";
import { routes } from "../../../routes";

interface MediaItem {
  id: string;
  title: string;
  category: string;
  date: string;
  images: string[];
}

export const DailyDarshan: React.FC = () => {
  const [mediaItems, setMediaItems] = useState<MediaItem[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchMediaItems = async () => {
      try {
        const response = await client.getEntries({
          content_type: "galleryFolder",
          order: ["-fields.date"] as [
            `fields.${string}` | `-fields.${string}` | "sys.contentType.sys.id" | "-sys.contentType.sys.id" | `fields.${string}.sys.id` | `-fields.${string}.sys.id`
          ],
        });

        const formattedItems = response.items.map((item: any) => ({
          id: item.sys.id,
          title: item.fields.title,
          category: item.fields.category || item.fields.title,
          date: item.fields.date,
          images: item.fields.images.map((img: any) => img.fields.file.url),
        }));

        setMediaItems(formattedItems);
      } catch (error) {
        console.error("Error fetching media items from Contentful:", error);
      }
    };

    fetchMediaItems();
  }, []);

  const groupByCategory = () => {
    const categories: Record<string, MediaItem[]> = {};
    mediaItems.forEach((item) => {
      const category = item.category;
      if (!categories[category]) categories[category] = [];
      categories[category].push(item);
    });

    // Sort the categories in the desired order
    const sortedKeys = ["Mangala Darshan", "Sringar Darshan", "Festival Darshan"];
    const sortedCategories: Record<string, MediaItem[]> = {};
    sortedKeys.forEach((key) => {
      if (categories[key]) {
        sortedCategories[key] = categories[key];
      }
    });

    return sortedCategories;
  };

  const categories = groupByCategory();

  return (
    <MediaPageWrapper>
      <TitleWrapper>
        <HeadingStyle>
          <h1>Daily Darshan Gallery</h1>
          <ImageTitle src={titleBaseImg} alt="Title Image" />
        </HeadingStyle>
      </TitleWrapper>
      {Object.keys(categories).map((category) => (
        <CategorySection key={category}>
          <CategoryHeader>
            <h2>{category}</h2>
            <ViewAllButton onClick={() => navigate(`${routes.viewAllPage}?category=${category}`)}>
              View All
            </ViewAllButton>
          </CategoryHeader>
          <FoldersContainer>
            {categories[category].slice(0, 4).map((folder) => (
              <FolderCard
                key={folder.id}
                onClick={() => navigate(`${routes.folderPage}?category=${(category)}&date=${(folder.date)}`)}
              >
                <FolderThumbnail src={folder.images[0]} alt="Thumbnail" />
                <FolderDetails>
                  <h3>{folder.title}</h3>
                  <p>{new Date(folder.date).toDateString()}</p>
                </FolderDetails>
              </FolderCard>
            ))}
          </FoldersContainer>
        </CategorySection>
      ))}
    </MediaPageWrapper>
  );
};

export default DailyDarshan;
