import React, { useState, useEffect, MouseEvent } from 'react';
import yt_logo from "../../../assets/images/yt_logo.png";
import {
  VideoCardWrapper,
  VideoThumbnail,
  ThumbnailImage,
  PlayOverlay,
  PlayIcon,
  VideoInfo,
  VideoTitle,
  VideoDate,
  VideoModal,
  ModalContent,
  CloseButton,
  IFrame,
} from './style'; 

// Define props type
interface VideoCardProps {
  video: {
    id: string;
    title: string;
    date: string;
    thumbnail: string;
  };
}

const VideoCard: React.FC<VideoCardProps> = ({ video }) => {
  const [isModalOpen, setModalOpen] = useState(false);

  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => setModalOpen(false);

  const handleOutsideClick = (e: MouseEvent<HTMLDivElement>) => {
    const target = e.target as HTMLDivElement;
  
    // Close modal only if the click is outside the modal content
    if (target.getAttribute("data-role") === "modal-overlay") {
      handleCloseModal();
    }
  };
  

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        handleCloseModal();
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, []);

  return (
    <>
      <VideoCardWrapper onClick={handleOpenModal}>
        <VideoThumbnail>
          <ThumbnailImage src={video.thumbnail} alt={video.title} />
          <PlayOverlay>
            <PlayIcon src={yt_logo} alt="YouTube Play Button" />
          </PlayOverlay>
        </VideoThumbnail>
        <VideoInfo>
          <VideoTitle>{video.title}</VideoTitle>
          <VideoDate>
            <span>🕒</span> {video.date}
          </VideoDate>
        </VideoInfo>
      </VideoCardWrapper>

      {isModalOpen && (
        <VideoModal data-role="modal-overlay" onClick={handleOutsideClick}>
          <ModalContent>
            <CloseButton onClick={handleCloseModal} aria-label="Close modal">
              ✖
            </CloseButton>
            <IFrame
              src={`https://www.youtube.com/embed/${video.id}`}
              title={video.title}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></IFrame>
          </ModalContent>
        </VideoModal>
      )}
    </>
  );
};

export default VideoCard;
