import React, { useEffect, useState } from "react";
import client from "../../../utils/contentfulClient";
import {
  DonationPageWrapper,
  DonationCategorySection,
  DonationFoldersContainer,
  DonationFolderCard,
  DonationFolderThumbnail,
  DonationFolderDetails,
} from "./style";
import { paragraphs } from "./constant";
import titleBaseImg from "../../../assets/images/title-base.png";
import { HeadingStyle, ImageTitle, TitleWrapper } from "../../TitleBase/style";
import { donationItems } from "./constant";
import { routes } from "../../../routes";

interface DynamicEvents {
  title: string;
  date: string;
  image: string[];
}

export const DonationMainPage: React.FC = () => {
  const [dynamicCards, setDynamicCards] = useState<DynamicEvents[]>([]);

  useEffect(() => {
    const fetchDynamicCards = async () => {
      try {
        const response = await client.getEntries({ content_type: "donation" });

        const cards = response.items
          .map((item: any) => ({
            title: item.fields.title,
            date: item.fields.date,
            image: Array.isArray(item.fields.image)
              ? item.fields.image.map((img: any) => img.fields.file.url)
              : item.fields.image
              ? [item.fields.image.fields.file.url]
              : [], // Ensure empty array if no images
          }))
          .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());

        setDynamicCards(cards);
      } catch (error) {
        console.error("Error fetching Contentful entries:", error);
      }
    };

    fetchDynamicCards();
  }, []);

  return (
    <DonationPageWrapper>
      <TitleWrapper>
        <HeadingStyle>Donate</HeadingStyle>
        <ImageTitle src={titleBaseImg} alt="Title Image" />
      </TitleWrapper>

      <DonationCategorySection>
        <p style={{ textAlign: "justify", color: "#ad0d0d", lineHeight: "1.5" }}>
          {paragraphs}
        </p>
      </DonationCategorySection>

      <DonationFoldersContainer>

        {/* Dynamic Cards */}
        {dynamicCards.map((card) => (
          <DonationFolderCard
            key={card.title}
            onClick={() =>
              window.location.href = routes.dynamicDonationPage.replace(":categoryKey", encodeURIComponent(card.title))
            }
          >
            <DonationFolderThumbnail
              src={card.image.length > 0 ? card.image[0] : "fallback-image-url"}
              alt="Donation Image"
            />
            <DonationFolderDetails>
              <h3>{card.title}</h3>
              <p style={{ color: "#ad0d0d" }}>
                {new Intl.DateTimeFormat("en-GB", {
                  day: "2-digit",
                  month: "long",
                  year: "numeric",
                }).format(new Date(card.date))}
              </p>
            </DonationFolderDetails>
          </DonationFolderCard>
        ))}

        {/* Static Cards */}
        {donationItems.map((item) => (
          <DonationFolderCard key={item.title} onClick={() => window.location.href = item.route}>
            <DonationFolderThumbnail src={item.image} alt="Donation Type" />
            <DonationFolderDetails>
              <h3>{item.title}</h3>
            </DonationFolderDetails>
          </DonationFolderCard>
        ))}

      </DonationFoldersContainer>
    </DonationPageWrapper>
  );
};

export default DonationMainPage;
