import styled from "styled-components";
import { devices } from "../../style";

export const TransparentDropdown = styled.div<{ isOpen?: boolean }>`
  display: inline-block;
  position: relative;
  z-index: 1;
  width: 95px;

  .dropdown-menu {
    border: none;
    box-shadow: none;
    background-color: #fffaf0;
  }

  .dropdown-toggle {
    color: #fff;
    font-size: 1.0rem;
    background: transparent;
    border: none;
    padding: 5px 10px;
    border-radius: 50px;

    @media (${devices.laptopM}) {
      font-size: 1.1rem;
      padding: 10px 20px;
    }

    &:hover,
    ${(props) => props.isOpen && "&"} {
      background-color: #ad0d0d;
      color: #fff;
      border-radius: 50px;
    }
  }

  .dropdown-item {
    color: #7e5959;
    padding: 0.25rem 1.5rem;
    font-size: 1.2rem;
  }

  .dropdown .dropdown-toggle:focus {
    outline: none;
    box-shadow: none;
    border-color: initial;
  }
`;

// Dropdown Toggle Wrapper
export const DropdownToggleWrapper = styled.div`
  color: #7e5959;
  font-family: Overlock-Regular, Arial, Helvetica, sans-serif;
  background-color: transparent;
  padding: 10px 15px;
  cursor: pointer;
  position: relative;
  transform: translateX(-20%);

  @media (${devices.laptopM}) {
    transform: translate(-35%, -5px);
  }
`;

// Dropdown Menu Wrapper
export const DropdownMenuWrapper = styled.div`
  background-color: #7e5959;
  font-family: Overlock-Regular, Arial, Helvetica, sans-serif;
`;