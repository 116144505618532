import { DonationCategory } from "../Payment/constant";

export const eHundi = [
  "Srimad Bhagavatam 10.41.43-52 says that after offering Lord Krishna and Balarama seats and bathing Their feet, a garland maker worshiped Them with arghya, garlands, paan, sandalwood paste and other presentations, Lord Krishna award him unshakable devotional service along with strength, long life, fame, beauty and ever-increasing prosperity for his family.",
];

export const donationCategories: DonationCategory[] = [
  {
    title: "E hundi",
    donations: [],
  },
];
