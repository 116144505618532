import React, { useEffect, useState } from 'react';
import client from '../../../utils/contentfulClient';
import VideoCard from './VideoCard';
import { ensureMaxVideos } from '../../../utils/contentfulUtils';
import { EntrySkeletonType } from 'contentful';
import titleBaseImg from "../../../assets/images/title-base.png";
import { HeadingStyle, ImageTitle, TitleWrapper } from "../../TitleBase/style";
import { KirtanLecturesPageWrapper, VideosGrid } from './style';

// Define the structure of video details returned by YouTube API
interface YouTubeVideoDetails {
  title: string;
  uploadDate: string;
}

// Define the structure of a video entry
interface Video {
  id: string;
  title: string;
  date: string;
  thumbnail: string;
  video: string;
}

// Define the structure of Contentful entry fields
interface ContentfulEntryFields {
  video: string; // Define your Contentful fields here
}

// Extend EntrySkeletonType to match your Contentful content type
interface ContentfulEntry extends EntrySkeletonType {
  fields: ContentfulEntryFields;
  contentTypeId: 'kirtansandLectures'; // Specify your Contentful content type ID
}

// Extract YouTube Video ID from the link
export const getYouTubeID = (url: string): string | null => {
  const regex = /(?:youtube\.com\/(?:.*v=|v\/|.*\/)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
  const match = url.match(regex);
  return match ? match[1] : null;
};

// Fetch video details from YouTube API
export const fetchYouTubeDetails = async (videoID: string): Promise<YouTubeVideoDetails | null> => {
  const API_KEY = process.env.REACT_APP_YOUTUBE_API_KEY;
  const url = `https://www.googleapis.com/youtube/v3/videos?id=${videoID}&key=${API_KEY}&part=snippet`;

  try {
    const response = await fetch(url);
    const data = await response.json();

    if (data.items && data.items.length > 0) {
      const snippet = data.items[0].snippet;
      return {
        title: snippet.title,
        uploadDate: snippet.publishedAt,
      };
    } else {
      console.error('No video data found for the given ID');
      return null;
    }
  } catch (error) {
    console.error('Error fetching YouTube video details:', error);
    return null;
  }
};

// Main Component
export const KirtanLecturesPage: React.FC = () => {
  const [videos, setVideos] = useState<Video[]>([]);

  useEffect(() => {
    const fetchVideos = async () => {
      await ensureMaxVideos(50);

      try {
        // Fetch data from Contentful
        const response = await client.getEntries<ContentfulEntry>({
          content_type: 'kirtansandLectures',
        });

        const videoData = await Promise.all(
          response.items.map(async (item) => {
            const videoLink = item.fields.video;

            if (!videoLink || typeof videoLink !== 'string') {
              console.warn(`Skipping entry with invalid or missing video field: ${item.sys.id}`);
              return null;
            }

            const videoID = getYouTubeID(videoLink);

            if (!videoID) {
              console.warn(`Skipping entry with invalid YouTube link: ${videoLink}`);
              return null;
            }

            const youtubeDetails = await fetchYouTubeDetails(videoID);
            if (!youtubeDetails) return null;

            return {
              id: videoID,
              title: youtubeDetails.title,
              date: new Date(youtubeDetails.uploadDate).toDateString(),
              thumbnail: `https://img.youtube.com/vi/${videoID}/hqdefault.jpg`,
              video: videoLink,
            } as Video;
          })
        );

        const filteredVideoData: Video[] = videoData.filter(
          (video): video is Video => video !== null
        );

        setVideos(filteredVideoData);
      } catch (error) {
        console.error('Error fetching videos:', error);
      }
    };

    fetchVideos();
  }, []);

  return (
    <KirtanLecturesPageWrapper>
      <TitleWrapper>
        <HeadingStyle>
          <h1>Kirtan & Lectures</h1>
          <ImageTitle src={titleBaseImg} alt="Title Image" />
        </HeadingStyle>
      </TitleWrapper>
      <VideosGrid>
        {videos.map((video) => (
          <VideoCard key={video.id} video={video} />
        ))}
      </VideosGrid>
    </KirtanLecturesPageWrapper>
  );
};

export default KirtanLecturesPage;
