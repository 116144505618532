import styled from "styled-components";
import { sizes } from "../../../style";

// Wrapper for the main page
export const MediaPageWrapper = styled.div`
  padding: 2%; /* Use percentage padding for better responsiveness */
  background-color: transparent;
  margin: 0 auto; /* Center the wrapper */
  width: 88%;
  box-sizing: border-box; /* Ensure padding is included in the total width */
`;


// Category Section
export const CategorySection = styled.div`
  margin-bottom: 40px;
`;

// Header for each category
export const CategoryHeader = styled.div`
  color: #ad0d0d;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  h2 {
    font-size: 1.5rem;
    margin: 0;

    @media (max-width: ${sizes.mobileL}) {
      font-size: 1.2rem; /* Adjust font size for mobile */
    }
  }
`;

// Button for viewing all items in a category
export const ViewAllButton = styled.button`
  background-color: #7e5959;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9rem;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #ad0d0d;
  }

  @media (max-width: ${sizes.mobileL}) {
    font-size: 0.8rem; /* Adjust font size for mobile */
    padding: 6px 12px; /* Adjust padding for mobile */
  }
`;

// Container for folder items
export const FoldersContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
`;

// Individual folder card
export const FolderCard = styled.div`
  cursor: pointer;
  flex: 1 1 calc(25% - 20px); // Ensures four items per row with a gap
  max-width: calc(25% - 20px);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background: #fff;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease;

  &:hover {
    transform: translateY(-5px);
  }

   @media (max-width: ${sizes.tablet}) {
    flex: 1 1 calc(50% - 20px);
    max-width: calc(50% - 20px);
  }

  @media (max-width: ${sizes.mobileL}) {
    flex: 1 1 100%;
    max-width: 100%;
  }
`;

// Thumbnail image in the folder card
export const FolderThumbnail = styled.img`
  width: 100%;
  height: auto;
  border-bottom: 1px solid #ddd;
`;

// Folder details
export const FolderDetails = styled.div`
  padding: 10px;

  h3 {
    font-size: 1rem;
    margin: 0;
  }

  p {
    margin: 5px 0 0;
    color: #666;
    font-size: 0.85rem;
  }
`;
