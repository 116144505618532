export const address =
  "Opp – B & B Polytechnic College, Mota Bazaar, Vallabh Vidya Nagar, Anand, Gujarat – 388 120.";
export const email = " iskconinfovvn@gmail.com";
export const guestHouse = "To Book Room, please contact us on:";
export const contactNumbers = [
  {
    id: 1,
    number: "02692 230796",
  },
  {
    id: 2,
    number: "99984 07654",
  },
];
export const guestHouseNumbers = [
  {
    id: 1,
    number: "02692 235752",
  },
  {
    id: 2,
    number: "02692 233012",
  },
];
